import React from 'react'
import './index.scss'
import { SectionSquaredSection } from '@utils/types'
import { BaseBlockContent } from '@utils/richText'
import { Row, Col } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FadeInSection } from '@components/Animations'

type Props = {
  squaredSection: SectionSquaredSection[]
}

const SquaredSection = ({ squaredSection }: Props) => {
  return (
    <section className="squared-section">
      {squaredSection.map((item, index) => (
        <Row
          noGutters
          className={`sanity-bg-${item.backgroundColor?.title} ${
            item.flip ? 'ss-fliped' : ''
          }`}
          key={index}
        >
          <Col md={6} xs={12}>
            <FadeInSection timeout={250}>
              <GatsbyImage
                image={item?.image?.asset?.gatsbyImageData}
                alt={item?.image?.alt}
                className="ss-item"
                imgStyle={{ maxWidth: '100vw', objectFit: 'cover' }}
              />
            </FadeInSection>
          </Col>

          <Col md={6} xs={12} className="ss-item">
            <FadeInSection timeout={250}>
              <BaseBlockContent blocks={item?._rawText} />
            </FadeInSection>
          </Col>
        </Row>
      ))}
    </section>
  )
}

export default SquaredSection
