import React, { useEffect } from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import SEO from '@components/seo'
import loadable from '@loadable/component'
import { SanityProcessPage } from '@utils/types'
import { GatsbyImage } from 'gatsby-plugin-image'
import SquaredSection from '@components/squaredSection'
import { FadeInSection } from '@components/Animations'
const OurTeam = loadable(() => import('@components/ourTeam'))
const SimpleTextSection = loadable(() =>
  import('@components/simpleTextSection'),
)
const CategoryDescription = loadable(() =>
  import('@components/categoryDescription'),
)

type Props = {
  data: {
    sanityProcess: SanityProcessPage
  }
  location: Location
}

const Process = ({ data: { sanityProcess }, location }: Props) => {
  const metadata = {
    location: location.href,
    ...sanityProcess?.seo,
  }

  return (
    <div className="process-page">
      <SEO {...metadata} />
      <section className="process-text-section">
        {sanityProcess?.simpleTextSection && (
          <SimpleTextSection
            simpleTextSection={sanityProcess?.simpleTextSection}
          />
        )}
      </section>
      {sanityProcess?.image && (
        <section className="process-image">
          <FadeInSection timeout={250}>
            <GatsbyImage
              className="process-image"
              image={sanityProcess?.image?.asset?.gatsbyImageData}
              alt={sanityProcess?.image?.alt}
            />
          </FadeInSection>
        </section>
      )}
      {sanityProcess?.categories?.length > 0 && (
        <CategoryDescription categories={sanityProcess?.categories} />
      )}
      {sanityProcess?.about && (
        <SquaredSection squaredSection={[sanityProcess?.about]} />
      )}
      {sanityProcess?.team && (
        <OurTeam
          title={sanityProcess?.team?._rawTitle}
          employees={sanityProcess?.team?.teamMembers}
        />
      )}
    </div>
  )
}

export default Process

export const PageQuery = graphql`
  query {
    sanityProcess(_id: { eq: "processPage" }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      simpleTextSection {
        _rawBody(resolveReferences: { maxDepth: 10 })
        _rawTitle(resolveReferences: { maxDepth: 10 })
      }
      image {
        alt
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            height: 700
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      categories {
        button {
          slug {
            current
          }
          title
          type
        }
        title
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
      about {
        flip
        image {
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              height: 700
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        _rawText(resolveReferences: { maxDepth: 10 })
      }
      team {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        teamMembers {
          image {
            alt
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 700
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          name
          position
        }
      }
    }
  }
`
